import React from 'react'
import AnimateFadeIn from '../animation/AnimateFadeIn'

// Components

import { Line } from './index.style'

const DividingLine = ({ contain = false }) => {
  const sectionDelay = 0.5
  return (
    <>
      <AnimateFadeIn delay={sectionDelay}>
        <Line contain={contain} />
      </AnimateFadeIn>
    </>
  )
}

export default DividingLine
