import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { flow } from '../../styles/utils/functions.style'
import { font } from '../../styles/vars/font.style'
import { textStylesConfig } from '../../styles/vars/textStyles.style'

export const RichTextMain = styled.div`
  ${flow()}

  strong {
    font-weight: ${font.secondary.weight.semibold};
  }

  ul,
  ol {
    padding-left: 1.1em;
    ${clamp(
      'font-size',
      textStylesConfig.body.size.min,
      textStylesConfig.body.size.max
    )}
  }
`
