import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading1,
  Heading2,
  Heading3,
  TextBody,
  TextBodyBold,
} from '../TextStyles'
// import AnimateSplitText from '../animation/AnimateSplitText'
import { RichTextMain } from './index.style'

const RichText = ({ content, delay, small }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading1 as="h2">{children}</Heading1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2 as="h3">{children}</Heading2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3 as="h4">{children}</Heading3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.toString().trim() === '') return

        return <TextBody>{children}</TextBody>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <Img
            fluid={node.data.target.fluid}
            alt={node.data.target.description}
            style={{ height: 'auto' }}
          />
        )
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  const smallBodyOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading1 as="h2">{children}</Heading1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2 as="h3">{children}</Heading2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3 as="h4">{children}</Heading3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.toString().trim() === '') return

        return <TextBodyBold>{children}</TextBodyBold>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <Img
            fluid={node.data.target.fluid}
            alt={node.data.target.description}
            style={{ height: 'auto' }}
          />
        )
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return (
    <RichTextMain>
      {renderRichText(content, small ? smallBodyOptions : options)}
    </RichTextMain>
  )
}

RichText.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
}

export default RichText
