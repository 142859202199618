import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'

export const Line = styled.div`
  height: 0.1rem;
  left: ${props => (props.contain ? '0' : '-2.2rem')};
  right: ${props => (props.contain ? '0' : '-2.2rem')};
  position: absolute;
  width: ${props => (props.contain ? undefined : '100vm')};

  ${mq.tabletL} {
    left: 0;
    right: 0;
  }

  &:before {
    background-color: ${colors.dark};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
  }
`
